<template>
  <section v-if="promo">
    <div class="container">
      <div class="bg-gradient-primary rounded-12 md:rounded-20 px-16 md:px-48 py-12 md:py-32 flex justify-between items-center">
        <div>
          <h5 class="text-h-s md:text-h-xl font-bold text-white !mb-2">
            {{ promo.title }}
          </h5>
          <p class="text-white text-b-xs md:text-b-m" v-html="promo.description" />
        </div>
        <div>
          <Link href="/register" class="no-underline">
            <se-button size="large" type="primary" class="!bg-white !px-48 hover:!bg-neutral-20 hidden md:block md:visible">
              <span class="text-transparent bg-clip-text bg-gradient-primary">{{ promo.ctaText }}</span>
            </se-button>
            <se-button size="small" type="primary" class="!bg-white hover:!bg-neutral-20 block md:hidden ml-0">
              <span class="text-transparent bg-clip-text bg-gradient-primary">{{ promo.ctaText }}</span>
            </se-button>
          </Link>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import SeButton from '../../../../../shared/ui-kit/button/Button.vue';
import { theme } from '../../../../../../theme';
import Link from '../../../../../components/Link.vue';

const { promo } = theme.home;
</script>

<script lang="ts">
export default {
  name: 'SeStaticHomePromo',
};
</script>
